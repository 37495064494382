export default {
  setLoading: (state, payload) => (state.loading = payload),
  setAvailableUsers: (state, payload) => (state.availableUsers = payload),
  setLocale(state, payload) {
    state.locale = payload;
    state.filter.locale = payload;
  },
  setName: (state, payload) => (state.filter.name = payload),
  setId: (state, payload) => (state.filter.id = payload),
  setRadius: (state, payload) => (state.filter.radius = payload),
  setZipCode: (state, payload) => (state.filter.zipCode = payload),
  setSlotsAvailable: (state, payload) =>
    (state.filter.slotsAvailable = payload),
  setContact: (state, payload) => (state.filter.contact = payload),
  setProducts: (state, payload) => (state.filter.products = payload),
  setTextSearchTags: (state, payload) =>
    (state.filter.textSearchTags = payload),
  setWithDescription: (state, payload) =>
    (state.filter.withDescription = payload),
  setNotificationFrequency: (state, payload) =>
    (state.filter.notificationFrequency = payload),
  setNotificationFrequencyDay: (state, payload) =>
    (state.filter.notificationFrequencyDay = payload),
  setNotificationFrequencyHour: (state, payload) =>
    (state.filter.notificationFrequencyHour = payload),
  setNotificationFrequencyWeekend: (state, payload) =>
    (state.filter.notificationFrequencyWeekend = payload),
  setUsers: (state, payload) => (state.filter.users = payload),
  setIsFavorite: (state, payload) => (state.filter.isFavorite = payload),

  /**
   * Meta
   */
  setFilterController: (state, payload) =>
    (state.meta.filterController = payload),
};
