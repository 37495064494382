import axios from "axios";

import { getEnv, singleton } from "@solvari/utils";

const proHttp = singleton(() =>
  axios.create({
    baseURL: getEnv().network.pro.baseWithPrefix,
    headers: {
      Authorization: `Bearer ${getEnv().auth.jwtToken}`,
    },
  }),
);

export default {
  fetchCustomerWorkplaceData(localeCode) {
    return proHttp().get(`customer/workplace/data/${localeCode}`);
  },

  fetchSavedFilter(localeCode) {
    return proHttp().get(`workplace/get-saved-filters/${localeCode}`);
  },

  favoriteFilter(id) {
    return proHttp().get(`workplace/set-favorite-filter/${id}`);
  },

  fetchFilter(id) {
    return proHttp().get(`workplace/get-filter/${id}`);
  },

  fetchFilters(locale) {
    return proHttp().get(`workplace/get-filters?locale=${locale}`);
  },

  fetchResults(params, signal) {
    return proHttp().get("workplace/results", {
      params,
      signal,
    });
  },

  saveFilter(filter) {
    return proHttp().post("workplace/save-filter", filter);
  },

  buyLead(leadId) {
    return proHttp().get(`workplace/buy/${leadId}`);
  },

  fetchLead(leadId) {
    return proHttp().get(`workplace/info/${leadId}`);
  },
};
