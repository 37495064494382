import type { VueIslandConfig } from "@solvari/common-fe/helpers";

import { initVueIslands } from "@solvari/common-fe/helpers";
import { initFontAwesome } from "@solvari/common-fe/integrations";
import { createPinia } from "pinia";

import { initPrimeVueThemed } from "@solvari/components";
import { useI18nCustomer } from "@solvari/translations";
import { getEnv } from "@solvari/utils";

import { leadDetails } from "@/vue/apps/customer/pages/leadDetails";
import { store } from "@/vue/apps/customer/store/store.js";
import { initAxiosInterceptors } from "@/vue/apps/shared/plugins/axios/index.js";
import SModal from "@/vue/apps/shared/plugins/sModal";

import "@/vue/apps/customer/utils/sentry";
import "@solvari/common-fe/polyfills";

import "@/platform/components/review-widget/reviewScore.css";
import "@/vue/apps/customer/styling/index.scss";

function main() {
  initFontAwesome();

  initAxiosInterceptors();

  const pages: VueIslandConfig = {
    productsPage: () =>
      import("@/vue/apps/customer/pages/CustomerProducts.vue"),
    workplace: () => import("@/vue/apps/customer/pages/Workplace.vue"),
    workplaceLead: () => import("@/vue/apps/customer/pages/WorkplaceLead.vue"),
    workplaceFilter: () =>
      import("@/vue/apps/customer/pages/WorkplaceFilter.vue"),
    workplaceFiltersOverview: () =>
      import("@/vue/apps/customer/pages/WorkplaceFiltersOverview.vue"),
    creditsOverview: () =>
      import("@/vue/apps/customer/pages/CreditsOverview.vue"),
    crmApi: () => import("@/vue/apps/customer/pages/CrmApi.vue"),
    createPassword: () =>
      import("@/vue/apps/customer/pages/auth/CreatePassword.vue"),
    userEmailVerification: () =>
      import(
        "@/vue/apps/customer/pages/verification/UserEmailVerification.vue"
      ),
  };

  const components: VueIslandConfig = {
    inbox: () => import("@/vue/components/inbox/InboxThread.vue"),
    leadSearch: () =>
      import("@/vue/apps/customer/components/lead-search/LeadSearch.vue"),
    walletBalance: () =>
      import("@/vue/apps/customer/components/wallet/WalletBalance.vue"),
    reviewInvite: () =>
      import("@/vue/apps/customer/components/review-invite/ReviewInvite.vue"),
    proDashboardCards: () =>
      import("@/pro/components/dashboard/cards/DashboardCards.vue"),
    serviceCard: () =>
      import("@/pro/components/dashboard/cards/service/ServiceCard.vue"),
    createCampaignModal: () =>
      import("@/vue/apps/customer/components/modal/CreateCampaignModal.vue"),
    editProject: () => import("@/pro/pages/project/EditProject.vue"),
    onboardingFlow: () =>
      import(
        "@/vue/apps/customer/components/onboarding-flow/OnboardingFlow.vue"
      ),
  };

  initVueIslands(
    { ...pages, ...components },
    (app) => {
      app.use(createPinia());
      app.use(store());
      app.use(SModal);
      initPrimeVueThemed(app);

      // TODO remove this i18n call when we use composition api more
      useI18nCustomer();
    },
    getEnv().config.localeCode,
  );

  leadDetails();
}

main();
