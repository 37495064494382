import { createLogger, createStore } from "vuex";

import { getEnv, singleton } from "@solvari/utils";

import workplaceFilter from "./modules/workplace-filter/index.js";
import workplace from "./modules/workplace/index.js";

export const store = singleton(() =>
  createStore({
    modules: {
      workplace,
      workplaceFilter,
    },
    strict: true,
    plugins: getEnv().config.isDev ? [createLogger()] : [],
  }),
);
