import { initSentryRoot } from "@solvari/common-fe/integrations";

import { getEnv } from "@solvari/utils";

import { store } from "@/vue/apps/customer/store/store";

export type AuthState = {
  accessToken: string;
  expiresAt: string;
  permissions: string[];
  refreshToken: string;
  roles: string[];
  user: {
    email: string;
    id: string;
    permissions: string[];
    pro: {
      id: string;
      locale: string;
    };
  } | null;
};

initSentryRoot({
  addData: () => {
    const { auth } = store().state as { auth: AuthState };
    const userStore = auth.user;
    const roleStore = auth.roles;

    return {
      tags: {
        proId: userStore?.pro.id,
        proLocale: userStore?.pro.locale,
        userId: userStore?.id,
      },
      contexts: {
        user: {
          roles: roleStore,
          permission: userStore?.permissions,
        },
      },
    };
  },
  environment: getEnv().config.envBE,
  name: "customer",
  version: import.meta.env.npm_package_version as string,
});
