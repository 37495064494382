// This file is for the customer.leads.view route. It's hacky but works

export function leadDetails() {
  const leadDetailsElement = document.querySelector(".lead-details");
  if (!leadDetailsElement) {
    return;
  }

  const tab = new URLSearchParams(window.location.search).get("tab");
  if (tab) {
    document
      .querySelector<HTMLAnchorElement>(`.inline-lead__tabs [href='#${tab}']`)
      ?.click();
  }
}
