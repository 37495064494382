export const workplaceEvents = {
  buyLead: "workplace.click_buy",
  boughtLead: "workplace.lead_bought",
  openBoughtLead: "workplace.click_to_bought_lead",
  notAllowedToBuyLead: "workplace.buy_lead_not_allowed",
  sortLeads: "workplace.sort_leads",
  autoScrollMoreLeads: "workplace.auto_scroll_more_leads",
  noLeadsToShow: "workplace.no_leads_to_show",
  productChanged: "workplace.filter_product_changed",
  textSearchTagsChanged: "workplace.filter_text_search_tags_changed",
  distanceChanged: "workplace.filter_km_changed",
  zipcodeChanged: "workplace.filter_postalcode_changed",
  competitionChanged: "workplace.filter_competition_changed",
  contactChanged: "workplace.filter_telephone_required_changed",
  saveFilter: "workplace.filter_click_to_save",
  chooseFilter: "workplace.filter_choose_saved_filter",
  saveFilterInModal: "workplace.modal_save_filter",
  discardFilterInModal: "workplace.modal_cancel_save_filter",
  addedProductToFilter: "workplace.filter_product_added",
  resetFilter: "workplace.filter_reset",
  discardCollapsibleFilter: "workplace.discard_collapsible_mobile_filter",
  applyCollapsibleFilter: "workplace.apply_collapsible_mobile_filter",
  showCollapsibleMobileFilter: "workplace.show_collapsible_mobile_filter",
  couldNotBuyLead: "workplace.could_not_buy_because",
  localeChanged: "workplace.change_locale",
  filterFinishedLoading: "workplace.time_to_load_complete_view",
  leftBeforeFilterFinishedLoading: "workplace.left_before_view_loaded",
  toggleFilter: "workplace.collapse_filter",
  toggleSavedFilters: "workplace.collapse_saved_filters",
  topUpWallet: "workplace.click_to_top_up_wallet",
  configurePayment: "workplace.click_to_payment_method",
} as const;
